import { Button, ButtonGroup } from "react-bootstrap";
import { useLocale } from "@/hooks/useLocale";
import { useLink } from "@/hooks/useLink";
import { placeholders } from "@/data/placeholders";

export const GravitasCareersButton = ({ className }) => {
  const locale = useLocale();
  const link = useLink();

  return (
    <ButtonGroup className={className}>
      <Button variant="secondary" href={link("careers")}>
        {placeholders[`header.button.text.${locale}`]}
      </Button>
    </ButtonGroup>
  );
};
