import SourceFlowForm from "@sourceflow-uk/sourceflowform";
import SourceFlowApplicationForm from "@sourceflow-uk/sourceflowapplicationform";
import { Helmet } from "react-helmet";
import classes from "./styles.module.scss";
import clsx from "classnames";
import { useLink } from "@/hooks/useLink";

/**
 *
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
export const Form = ({ className, formId = null, jobId = null, onSubmitDone = null }) => {
  const link = useLink();

  if (formId === null && jobId == null) {
    return null;
  }

  const handleSubmit = () => {
    if (typeof onSubmitDone === "function") {
      return onSubmitDone();
    }

    if (typeof window !== "undefined" && jobId !== null) {
      window.location.href = link("jobs.complete");
    }

    if (typeof window !== "undefined" && formId !== null) {
      window.location.href = link("jobs.form.complete");
    }
  };

  return (
    <div className={clsx(className, classes.form)}>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/choices.js/public/assets/styles/choices.min.css"
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
        <link id="fa" rel="stylesheet" href="https://use.fontawesome.com/0348a6c5fa.css" />
      </Helmet>
      {jobId ? (
        <SourceFlowApplicationForm jobId={jobId} onSubmitDone={handleSubmit} />
      ) : (
        <SourceFlowForm formId={formId} onSubmitDone={handleSubmit} />
      )}
    </div>
  );
};
