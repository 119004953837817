import React from "react";
import SourceFlowText from "@sourceflow-uk/sourceflowtext";
import { getGlobal } from "@/functions/getGlobal";
import { placeholders } from "@/data/placeholders";
import { cleanMarkup } from "@/functions/cleanMarkup";

/**
 *
 * @param key
 * @returns {JSX.Element}
 * @constructor
 */
export const DynamicHtml = ({ path, tag = "div", dangerouslySetInnerHTML, ...props }) => {
  let htmlProps = {
    type: "html",
    global: getGlobal(),
    path,
    children: <span dangerouslySetInnerHTML={dangerouslySetInnerHTML ?? { __html: placeholders[path] }} />,
    middleware: (str, path) => {
      const string = cleanMarkup(str)
        .replaceAll('rel="noopener noreferrer" target="_blank"', "") // Removes evil react-quill everythings an external link issue
        .replaceAll("https://gravitas-group.sites.sourceflow.co.uk", ""); // Remove absolute links to staging

      // Reduces sizing of h2's & h3's added to content components
      if (path.includes(".content.details.") || path.includes(".content.text.")) {
        return string.replaceAll("<h2>", '<h2 class="h4">').replaceAll("<h3>", '<h3 class="h4">');
      }

      return string;
    },
  };

  return React.createElement(tag, props, <SourceFlowText {...htmlProps} />);
};
