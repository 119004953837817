import data from "../../.sourceflow/menu_main.json";
import BaseCollection from "@sourceflow-uk/sourceflow-sdk/base_collection";

export const getMainMenu = (mobileVersion = false) => {
  const collection = new BaseCollection(data, "en").getItems();

  return collection
    .filter((i) => {
      if (mobileVersion) {
        return i.parent.id === null;
      }

      return "mobile_only" in i ? i.parent.id === null && i.mobile_only === false : i.parent.id === null;
    })
    .map((i) => {
      const children = collection
        .filter((c) => {
          if (mobileVersion) {
            return c.parent.id === i.id;
          }

          return "mobile_only" in i ? c.parent.id === i.id && i.mobile_only === false : c.parent.id === i.id;
        })
        .map((c) => {
          const children = collection.filter((cc) => {
            if (mobileVersion) {
              return cc.parent.id === c.id;
            }

            return "mobile_only" in i ? cc.parent.id === c.id && i.mobile_only === false : cc.parent.id === c.id;
          });

          return children ? { ...c, children } : c;
        });

      return children ? { ...i, children } : i;
    });
};
